import React from 'react';
import { Alert, Button, Card, Col, Container, Form, ListGroup, Modal, Row } from 'react-bootstrap';

import {CanvasTextElem, CanvasTextElement, EventQRCanvas} from './EventPosterElements.js';
import SiteURL from '../common/SiteURL.js';

function getEventURL(eventID, tracker = null) {
    let data = {event_id: eventID};
    if (tracker) {
        data['t'] = tracker;
    }
    return new SiteURL()
        .event()
        .setParams(data)
        .getAbsolute();
}

const BLANK = 'BLANK';
const DefaultPosterTemplate = {
    BLANK: {
        name: 'Blank',
        path: null,
        left: '10',
        top: '10',
        size: 512,
    }
}


export default function EventPosterGenerator({eventID}) {
    const [tracker, setTracker] = React.useState('');

    // Initially, the only generic option is BLANK. Subsequently
    // the server side might return more poster data.
    const [posterName, setPosterName] = React.useState(BLANK);
    const [posterTemplate, setPosterTemplate] = React.useState(DefaultPosterTemplate);
    const data = posterTemplate[posterName];

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [eventData, setEventData] = React.useState([]);

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/event/fetch_event/?event_id=' + eventID)
            .then(res => res.json())
            .then(
                  (payload) => {
                      setEventData(payload);
                      if (payload.ticket_sku && 'poster' in payload.ticket_sku.metadata) {
                          let poster_data = payload.ticket_sku.metadata['poster'];
                          poster_data[BLANK] = DefaultPosterTemplate[BLANK];
                          setPosterTemplate(poster_data);
                      }
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, []);

    const [posterPath, setPosterPath] = React.useState(data['path']);
    const [leftOffset, setLeftOffset] = React.useState(data['left']);
    const [topOffset, setTopOffset] = React.useState(data['top']);
    const [qrSize, setQRSize] = React.useState(data['size']);
    const [validated, setValidated] = React.useState(false);
    const [textElems, setTextElems] = React.useState({})
    const [flip, setFlip] = React.useState(false);

    const stupid = React.useCallback(() => setFlip(!flip), [flip]);

    const url = getEventURL(eventID, tracker);

    const handleAddText = React.useCallback((e) => {
        let elem_id = Object.keys(textElems).length;
        let elem = new CanvasTextElem(elem_id)
        let elems = textElems;
        elems[elem.idx] = elem;
        setTextElems(elems);
        stupid();
    }, [textElems, flip]);

    const handleDownload = React.useCallback((e) => {
        let canvas = document.getElementById('mainPosterCanvas');

        let dataURL = canvas.toDataURL('image/png');
        // Create a dummy link text that triggers download
        let a = document.createElement('a');
        // Set the link to the image so that when clicked, the image begins downloading
        a.href = dataURL;
        a.download = eventID + '_' + tracker + '_poster.png';
        // Click on the link to set off download
        a.click();
    }, [eventID, tracker]);
    
    return (
        <div>
            <Card className="my-3">
                <Card.Header> Generate Event Link With Tracker </Card.Header>
                <Card.Body>
                    
                    <Form noValidate validated={validated}>
                        <Form.Group as={Row} className="mb-3" controlId="formName">
                            <Form.Label column>Tracking Token</Form.Label>
                            <Col>
                                <Form.Control
                                    required
                                    value={tracker}
                                    type="text"
                                    placeholder="(no spaces/special chars)"
                                    onChange={e => setTracker(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPoster">
                            <Form.Label column>Select Poster Template</Form.Label>
                            <Col>
                                <Form.Select
                                    aria-label="Select Poster Template"
                                    onChange={e => {
                                        const temp = e.target.value;
                                        const data = posterTemplate[temp];
                                        
                                        setPosterName(temp);
                                        setPosterPath(data['path']);
                                        setLeftOffset(data['left']);
                                        setTopOffset(data['top']);
                                        setQRSize(data['size']);
                                    }}
                                    value={posterName}
                                >
                                    {
                                        Object.entries(posterTemplate).map(
                                            ([k, v]) => <option value={k}>{v['name']}</option>
                                        )
                                    }
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formLeftPos">
                            <Form.Label column>QR Code Left Position</Form.Label>
                            <Col>
                                <Form.Control
                                    value={leftOffset}
                                    type="text"
                                    placeholder="Left Offset"
                                    onChange={e => setLeftOffset(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formTopPos">
                            <Form.Label column>QR Code Top Position</Form.Label>
                            <Col>
                                <Form.Control
                                    value={topOffset}
                                    type="text"
                                    placeholder="Top Offset"
                                    onChange={e => setTopOffset(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} className="mb-3" controlId="formQRSize">
                            <Form.Label column>QR Code Size</Form.Label>
                            <Col>
                                <Form.Control
                                    value={qrSize}
                                    type="text"
                                    placeholder="QR Size"
                                    onChange={e => setQRSize(e.target.value)}
                                />
                            </Col>
                        </Form.Group>

                        <Button
                            className="mx-3"
                            variant="primary"
                            onClick={(e) => handleDownload(e)}>
                            Download Poster
                        </Button>

                        <Button
                            className="mx-3"
                            variant="secondary"
                            onClick={(e) => handleAddText(e)}>
                            Add Text Element
                        </Button>
                        
                    </Form>
                </Card.Body>
            </Card>

            {Object.keys(textElems).map((k) =>
                <CanvasTextElement
                    textElement={textElems[k]}
                    handleRemoveElement={(e) => {
                        let elems = textElems;
                        delete elems[e.idx];
                        setTextElems(elems);
                        stupid();
                    }}
                    handleEditElement={(e) => {
                        let elems = textElems;
                        elems[e.idx] = e;
                        setTextElems(elems);
                        stupid();
                    }}
                />
            )}

            <Card className="my-3">
                URL: <a href={url}>{url}</a>
            </Card>
            
            <div className="my-3 eventPoster">
                <div className="posterFloatingQR">
                    <EventQRCanvas
                        textElements={textElems}
                        link={url}
                        poster={posterPath}
                        qrLeft={leftOffset}
                        qrTop={topOffset}
                        background="#ffffff"
                        backgroundAlpha={0.1}
                        foregroundAlpha={0.9}
                        level="H"
                        size={qrSize}
                    />
                </div>
            </div>
        </div>
        
    );
    
}
