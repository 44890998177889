import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import QRious from 'qrious';


export class CanvasTextElem {
    static counter = 0;
    idx = null;
    size = '48';
    font = 'Roboto';
    leftOffset = '96';
    topOffset = '96';
    text = 'Text';
    bold = false;

    constructor() {
        this.idx = 'text-' + CanvasTextElem.counter;
        CanvasTextElem.counter += 1;
    }

    renderTo(ctx) {
        ctx.font = this.getFontStr();
        ctx.fillText(this.text, this.leftOffset, this.topOffset);
        return this;
    }
    
    getFontStr() {
        return (this.bold ? 'bold ' : '') + this.size + 'px ' + this.font;
    }
}

export function CanvasTextElement({textElement, handleEditElement, handleRemoveElement}) {
    const [size, setSize] = React.useState(textElement.size);
    const [font, setFont] = React.useState(textElement.font);
    const [leftOffset, setLeftOffset] = React.useState(textElement.leftOffset);
    const [topOffset, setTopOffset] = React.useState(textElement.topOffset);
    const [text, setText] = React.useState(textElement.text);
    const [bold, setBold] = React.useState(textElement.bold);
    
    return (
        <Card className="my-3">
            <Card.Header>
                Text Element: {text}
                <Button
                    size="sm"
                    style={{float: 'inline-end'}}
                    variant="secondary"
                    onClick={(e) => handleRemoveElement(textElement)}>
                    Remove Text Element
                </Button>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column>Text</Form.Label>
                        <Col>
                            <Form.Control
                                required
                                value={text}
                                type="text"
                                placeholder=""
                                onChange={e => {
                                    setText(e.target.value);
                                    textElement.text = e.target.value;
                                    handleEditElement(textElement);
                                }}
                            />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column>Font</Form.Label>
                        <Col>
                            <Form.Select
                                aria-label="Select Font"
                                onChange={e => {
                                    setFont(e.target.value);
                                    textElement.font = e.target.value;
                                    handleEditElement(textElement);
                                }}
                                value={font}
                            >
                                <option value={'serif'}>Serif</option>
                                <option value={'Roboto'}>Roboto</option>
                            </Form.Select>
                            <Form.Control
                                value={size}
                                type="text"
                                placeholder="Font Size"
                                onChange={e => {
                                    setSize(e.target.value);
                                    textElement.size = e.target.value;
                                    handleEditElement(textElement);
                                }}
                            />
                            <Form.Check
                                type="checkbox"
                                checked={bold}
                                label="Bold"
                                onClick={e => {
                                    setBold(e.target.checked);
                                    textElement.bold = e.target.checked;
                                    handleEditElement(textElement);
                                }}
                            />

                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column>Position</Form.Label>
                        <Col>
                            <Form.Control
                                value={leftOffset}
                                type="text"
                                placeholder="Left Offset"
                                onChange={e => {
                                    setLeftOffset(e.target.value);
                                    textElement.leftOffset = e.target.value;
                                    handleEditElement(textElement);
                                }}
                            />
                            <Form.Control
                                value={topOffset}
                                type="text"
                                placeholder="Top Offset"
                                onChange={e => {
                                    setTopOffset(e.target.value);
                                    textElement.topOffset = e.target.value;
                                    handleEditElement(textElement);                                
                                }}
                            />
                        </Col>
                    </Form.Group>                    
                </Form>
            </Card.Body>
        </Card>        
    )
}

export function EventQRCanvas({textElements, link, poster, qrLeft, qrTop,...props}) {
    const qrCanvasRef = React.useRef(null)
    const posterCanvasRef = React.useRef(null)

    React.useEffect(() => {  
        const canvas = qrCanvasRef.current;
        let qr = new QRious({
            element: canvas,
            value: link,
            ...props,
        });
        return () => {
        };
    }, [link, props]);

    React.useEffect(() => {
        const canvas = posterCanvasRef.current;
        const context = canvas.getContext('2d');
        const qr = qrCanvasRef.current;

        let img = new Image();
        
        // Handle special case for blank image
        if (poster === null) {
            // default to letter size
            canvas.width = 850;
            canvas.height = 1100;
            if (props.size > 0) {
                context.drawImage(qr, qrLeft, qrTop);
            }
            for (const [key, text] of Object.entries(textElements)) {
                text.renderTo(context);
            }
            return;
        }
        
        img.src = poster;
        img.addEventListener("load", () => {
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);
            if (props.size > 0) {
                context.drawImage(qr, qrLeft, qrTop);
            }
            for (const [key, text] of Object.entries(textElements)) {
                text.renderTo(context);
            }
        });

    }, [poster, link, props, textElements]);
    
    return (
        <div>
            <div style={{display: "none"}}>
                <canvas ref={qrCanvasRef} />                
            </div>
            <canvas id="mainPosterCanvas" ref={posterCanvasRef} />
        </div>
    );
}
